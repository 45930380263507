.upload-area {
    width: 100%;
    /* height: 100px; */
    border: 2px dashed gray;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
    cursor: pointer;
}

.file-previews {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-height: 100%;
    overflow-y: auto;
}

.file-item {
    position: relative;
    width: 70px;
    height: 70px;
}

.file-preview {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.file-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: #f0f0f0;
    font-size: 65px;
    color: gray;
}

.remove-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-more-box {
    width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    border: 2px dashed gray;
    cursor: pointer;
    font-size: 24px;
    color: gray;
}